<template>
    <div>
        <v-card-title>
            Continuation and Termination Func<v-btn color="indigo lighten-1" text to="majority-vote-table">Majority-Vote-Table</v-btn>
            <v-col
                cols="12"
                sm="3"
            >
                <v-btn
                    icon
                    color="green"
                    @click="getFunc()"
                    >
                    <v-icon>mdi-cached</v-icon>
                </v-btn>
            </v-col>
        </v-card-title>

        <!--<v-btn
            text
            class="ma-2"
            @click="getFunc()"
        >
            GET-FUNC
        </v-btn>-->
        <v-card class="pa-4 ma-4" :color="cardColor" shaped>
            <v-container>
                <v-row class="text-caption">
                    <v-col cols="12" class="pb-0">
                        <!--<v-icon>mdi-comment-question-outline</v-icon>-->
                        aggregation
                        <codemirror v-model="node.aggregation" :options="cmOptions"/>
                    </v-col>
                    
                </v-row>


            </v-container>
        </v-card>

        <v-card class="pa-4 ma-4" :color="cardColor" shaped>
            <v-container>
                <v-row class="text-caption">
                    <v-col cols="12" class="pb-0">
                        <!--<v-icon>mdi-comment-question-outline</v-icon>-->
                        continuation
                        <codemirror v-model="node.condition" :options="cmOptions"/>
                    </v-col>
                    <!--<v-col cols="12" class="pb-0" v-if="node.statement==duct.APP_WSD.enums.Statement.WHILE">
                        <v-icon>mdi-repeat</v-icon>
                        LOOP condition
                        <v-card height=100><codemirror v-model="node.condition" :options="cmOptions" /></v-card>
                    </v-col>
                    <v-col cols="12" class="pb-0" v-if="node.skippable">
                        <v-icon>mdi-transit-skip</v-icon>
                        Skippable
                    </v-col>
                    <v-col cols="12" class="pb-0" v-if="hasNanotask">
                        <v-chip dark label outlined color="indigo" @click="$refs.dlgList.show=true">
                            <v-icon left>mdi-file-document-multiple-outline</v-icon>
                            Nanotasks ({{ nanotasks.length }})
                        </v-chip>
                    </v-col>-->
                </v-row>

                <!--<v-row justify="end" :key="idx" v-for="(child, idx) in children">
                    <v-col cols="12" md="11">
                    <recursive-batch
                        :duct="duct"
                        :name="name"
                        :parent-params="{
                            prjName, templateColor,
                            node: child,
                            isLast: idx==children.length-1,
                            depth: depth+1
                        }" />
                    </v-col>
                </v-row>-->

            </v-container>
        </v-card>

        <v-card class="pa-4 ma-4" :color="cardColor" shaped>
            <v-container>
                <v-row class="text-caption">
                    <v-col cols="12" class="pb-0">
                        <!--<v-icon>mdi-comment-question-outline</v-icon>-->
                        termination
                        <codemirror v-model="node.termination" :options="cmOptions"/>
                    </v-col>
                    
                </v-row>


            </v-container>
        </v-card>

    </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/python/python'
import 'codemirror/theme/base16-light.css'

export default {
    //name: "RecursiveBatch",
    //props: ["duct", "name", "parentParams"],
    props: ["duct", "prjName", "tmplName"],
    components: {
        //RecursiveBatch: () => import("./RecursiveBatch"),
        //Arrow: () => import("./Arrow"),
        //DialogImport: () => import("./DialogImportNanotasks"),
        //DialogList: () => import("./DialogListNanotasks"),
        codemirror
    },
    data: () => ({
        color: "grey",
        cmOptions: {
            tabSize: 4,
            mode: 'text/x-python',
            //theme: 'base16-light',
            lineNumbers: true,
            line: true,
            readOnly: true,
            //collapseIdentical: false,
            //highlightDifferences: true,
            //styleActiveLine: true,
            //foldGutter: true,
            //matchBrackets: true,
            //lineWrapping: true,
            viewportMargin: Infinity,
            //autofocus: true
            //refresh
        },
        nanotasks: [],
        parentParams: {
            prjName: null,
            node: "None",
            templateColor: null,
            depth: null
        }
    }),
    computed: {
        cardColor() { return this.isBatch ? `${this.color} lighten-${this.depth+2}` : this.templateColor; },
        //children() { return this.node ? this.node.children : []; },
        //isBatch() { return this.children ? this.children.length>0 : false; },
        //hasNanotask() { return !this.isBatch && this.nanotasks.length>0; },

        //prjName() { return this.parentParams.prjName; },
        node() { return this.parentParams.node; },
        templateColor() { return this.parentParams.templateColor; },
        depth() { return this.parentParams.depth; },
        //isLast() { return this.parentParams.isLast; },
    },
    watch: {
        tmplName() {
            this.duct.controllers.resource.getMajorityVoteFunc(this.prjName, this.tmplName);
        }
    },
    methods: {
        getFunc() {
            this.duct.controllers.resource.getMajorityVoteFunc(this.prjName, this.tmplName);
        }
    },
    created() {
        console.log(this.prjName);
        this.duct.invokeOrWaitForOpen(() => {
            this.duct.eventListeners.resource.on("getMajorityVoteFunc", {
                success: (data) => {
                    //this.parentParams.prjName = data["projectName"];
                    this.parentParams.node = data["MVFunc"];
                    this.parentParams.templateColor = "blue-grey lighten-4";
                    this.parentParams.depth = 1
                }
            });
        });

    }
}
</script>
<style>
.CodeMirror {
    font-family: Monaco, 'Andale Mono', 'Lucida Console', 'Bitstream Vera Sans Mono', 'Courier New';
    border: 1px solid #eee;
    height: auto;
}
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    z-index: 3;
}
</style>

